<template>
  <div class="transfer">
    <div class="heading">
      <h2>Security Questions</h2>
      <p>Update your security questions</p>
    </div>

    <form class="form" @submit.prevent="submitForm" novalidate="true" autocomplete="off">
      <div class="form__group--wrapper">
        <div
          class="form__group--four"
          v-loading="loadingTwo"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <label for="questionOne" class="form__label">
            <select
              name="questionOne"
              id="questionOne"
              class="form__input form__select"
              v-model.lazy="$v.form.questionOne.$model"
              :class="{
                'is-invalid': submitted && $v.form.questionOne.$error,
              }"
            >
              <option disabled value="">Security Question 1</option>
              <option
                v-for="question in questions"
                :key="question.questionId"
                :value="question.questionId"
              >
                {{ question.question }}
              </option>
            </select>
          </label>
          <small class="error" v-if="submitted && !$v.form.questionOne.required"
            >Security Question 1 is required</small
          >
        </div>

        <div class="form__group--four">
          <label for="answerOne">
            <input
              type="text"
              id="answerOne"
              name="answerOne"
              placeholder="--Answer--"
              class="form__input"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
              v-model.lazy="$v.form.answerOne.$model"
              :class="{ 'is-invalid': submitted && $v.form.answerOne.$error }"
          /></label>
          <small class="error" v-if="submitted && !$v.form.answerOne.required"
            >Security Question 1 answer is required</small
          >
        </div>
      </div>

      <div class="form__group--wrapper">
        <div
          class="form__group--four"
          v-loading="loadingTwo"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <label for="questionTwo" class="form__label">
            <select
              name="questionTwo"
              id="questionTwo"
              class="form__input form__select"
              v-model.lazy="$v.form.questionTwo.$model"
              :class="{
                'is-invalid': submitted && $v.form.questionTwo.$error,
              }"
            >
              <option disabled value="">Security Question 2</option>
              <option
                v-for="question in questions"
                :key="question.questionId"
                :value="question.questionId"
              >
                {{ question.question }}
              </option>
            </select>
          </label>
          <small class="error" v-if="submitted && !$v.form.questionTwo.required"
            >Security Question 2 is required</small
          >
        </div>

        <div class="form__group--four">
          <label for="answerTwo">
            <input
              type="text"
              id="answerTwo"
              name="answerTwo"
              placeholder="--Answer--"
              class="form__input"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
              v-model.lazy="$v.form.answerTwo.$model"
              :class="{ 'is-invalid': submitted && $v.form.answerTwo.$error }"
          /></label>
          <small class="error" v-if="submitted && !$v.form.answerTwo.required"
            >Security Question 2 answer is required</small
          >
        </div>
      </div>

      <div class="form__group--wrapper">
        <div
          class="form__group--four"
          v-loading="loadingTwo"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <label for="questionThree" class="form__label">
            <select
              name="questionThree"
              id="questionThree"
              class="form__input form__select"
              v-model.lazy="$v.form.questionThree.$model"
              :class="{
                'is-invalid': submitted && $v.form.questionThree.$error,
              }"
            >
              <option disabled value="">Security Question 3</option>
              <option
                v-for="question in questions"
                :key="question.questionId"
                :value="question.questionId"
              >
                {{ question.question }}
              </option>
            </select>
          </label>
          <small class="error" v-if="submitted && !$v.form.questionThree.required"
            >Security Question 3 is required</small
          >
        </div>

        <div class="form__group--four">
          <label for="answerThree">
            <input
              type="text"
              id="answerThree"
              name="answerThree"
              placeholder="--Answer--"
              class="form__input"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
              v-model.lazy="$v.form.answerThree.$model"
              :class="{ 'is-invalid': submitted && $v.form.answerThree.$error }"
          /></label>
          <small class="error" v-if="submitted && !$v.form.answerThree.required"
            >Security Question 3 answer is required</small
          >
        </div>
      </div>

      <div class="form__group--wrapper">
        <div class="form__group--three form__checkbox">
          <label for="terms" class="styled-checkbox">
            <input
              type="checkbox"
              name="terms"
              id="terms"
              v-model="form.terms"
              :class="{ 'is-invalid': submitted && $v.form.terms.$error }"
            />
            <span class="checkmark"></span>
          </label>
          <p>
            I AGREE TO
            <span @click="termsVisible = true">TERMS AND CONDITIONS</span>
          </p>
        </div>
        <small class="error" v-if="submitted && !$v.form.terms.required"
          >Terms & Conditions needs to be acknowledged.</small
        >
      </div>

      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Proceed</span>
      </button>
    </form>

    <el-dialog
      :visible.sync="termsVisible"
      :show-close="false"
      :close-on-click-modal="false"
      style="padding: 0; text-align: left"
      class="dialogue"
    >
      <div class="dialogue__heading">
        <h2>Terms and Conditions</h2>
        <div class="dialogue__close" @click="termsVisible = false">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3 10.0006L19.4873 3.81337C20.1709 3.12974 20.1709 2.02141 19.4873 1.33885L18.6624 0.514004C17.9786 -0.169839 16.8703 -0.169839 16.1877 0.514004L10.0006 6.70106L3.81337 0.512722C3.12974 -0.170907 2.02141 -0.170907 1.33885 0.512722L0.512722 1.33756C-0.170907 2.02141 -0.170907 3.12974 0.512722 3.8123L6.70106 10.0006L0.514004 16.1877C-0.169839 16.8715 -0.169839 17.9799 0.514004 18.6624L1.33885 19.4873C2.02247 20.1709 3.13081 20.1709 3.81337 19.4873L10.0006 13.3L16.1877 19.4873C16.8715 20.1709 17.9799 20.1709 18.6624 19.4873L19.4873 18.6624C20.1709 17.9786 20.1709 16.8703 19.4873 16.1877L13.3 10.0006Z"
              fill="#DB353A"
            />
          </svg>
        </div>
      </div>
      <section class="terms">
        <p>
          These Terms and Conditions is a contract between you and Sterling Bank Plc
          (“Sterling”) governing your use of your OneBank account and the services made
          available to you on OneBank.
        </p>
      </section>
      <div class="btn__group">
        <button class="btn btn__black" @click="termsVisible = false">Close</button>
        <button class="btn btn__red" @click="(form.terms = true), (termsVisible = false)">
          Accept
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  computed: mapState(["user"]),
  data() {
    return {
      loading: false,
      loadingTwo: false,
      submitted: false,
      questions: null,
      termsVisible: false,
      form: {
        questionOne: "",
        questionTwo: "",
        questionThree: "",
        answerOne: "",
        answerTwo: "",
        answerThree: "",
        terms: "",
      },
    };
  },
  validations: {
    form: {
      questionOne: { required },
      questionTwo: { required },
      questionThree: { required },
      answerOne: { required },
      answerTwo: { required },
      answerThree: { required },
      terms: { required },
    },
  },
  methods: {
    clearData() {
      this.form.questionOne = "";
      this.form.questionTwo = "";
      this.form.questionThree = "";
      this.form.answerOne = "";
      this.form.answerTwo = "";
      this.form.answerThree = "";
      this.form.terms = "";
      this.submitted = false;
    },
    getQuestions() {
      this.loadingTwo = true;

      api
        .getSecurityQuestions()
        .then((response) => {
          this.loadingTwo = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let securityQuestions = response.Data;
            this.questions = securityQuestions;
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loadingTwo = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      let payload = {
        smId: this.user.profileInfo.SMID,
        qa: [
          {
            questionId: this.form.questionOne,
            answer: this.form.answerOne,
          },
          {
            questionId: this.form.questionTwo,
            answer: this.form.answerTwo,
          },
          {
            questionId: this.form.questionThree,
            answer: this.form.answerThree,
          },
        ],
      };

      this.loading = true;
      api
        .setSecurityQuestions(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `Security questions set successfully.`,
              type: "success",
              duration: 10000,
            });
            this.clearData();
            this.getQuestions();
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
  mounted() {
    this.getQuestions();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
